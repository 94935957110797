import React from 'react';
import * as Styled from './propanePopUpStyles';
import constants from '../../utils/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';

const PropanePopUp = ({ modalShow, handleModalShow }) => {
  const propanePopUpQuery = useStaticQuery(graphql`
    query propaneQuery {
      allContentfulColumbiaFuel(
        filter: {
          node_locale: { eq: "en-US" }
          identifier: { type: { eq: "homePropanePopup" } }
        }
      ) {
        edges {
          node {
            id
            title
            identifier {
              type
            }
            slug
            sections {
              ... on ContentfulContentList {
                id
                type
                title
                list {
                  id
                  title
                  type
                  contentDetails {
                    raw
                  }
                }
              }
              ... on ContentfulContent {
                id
                contentDetails {
                  raw
                }
                title
                type
              }
              ... on ContentfulImageWithPath {
                id
                title
                type
                richText {
                  raw
                }
                image {
                  file {
                    url
                  }
                }
                path
              }
            }
          }
        }
      }
    }
  `);
  const propanePopUpData =
    propanePopUpQuery.allContentfulColumbiaFuel.edges[0].node.sections;

  const headerContent = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.PROPANE_POP_UP.HEADER;
  })[0];

  const forHomeContent = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.PROPANE_POP_UP.FOR_HOME;
  })[0];

  const forBusinessContent = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.PROPANE_POP_UP.FOR_BUSINESS;
  })[0];
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        if (asset) {
          return (
            <Styled.TopImage
              src={asset?.node?.image?.file?.url}
              alt={asset?.node?.image?.file?.title}
            />
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const optionsStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText2>{children}</Styled.ParaText2>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Styled.LinkStyle
            href={
              isExternalUrlHref(node?.data?.uri)
                ? node?.data?.uri
                : process.env.GATSBY_DOMAIN_URL + node?.data?.uri
            }
            target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
          >
            {children}
          </Styled.LinkStyle>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <>
      <Styled.MainModal
        show={modalShow}
        onHide={() => handleModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Styled.CloseImage
          onClick={() => {
            handleModalShow(false);
          }}
        />
        <Styled.CustomerSelect>
          {documentToReactComponents(
            JSON.parse(headerContent?.contentDetails?.raw),
            optionsMainStyle
          )}
          <Styled.RowWrapper>
            <Styled.Column xs={12} md={6}>
              <Styled.LinkStyle
                href={
                  isExternalUrlHref(forHomeContent?.path)
                    ? forHomeContent?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + forHomeContent?.path
                      )
                }
                target={
                  isExternalUrl(forHomeContent?.path) ? '_blank' : '_self'
                }
              >
                <Styled.TopImage
                  src={forHomeContent?.image?.file?.url}
                  alt={forHomeContent?.image?.file?.title}
                />
                {documentToReactComponents(
                  JSON.parse(forHomeContent?.richText?.raw),
                  optionsStyle
                )}
              </Styled.LinkStyle>
            </Styled.Column>
            <Styled.Column xs={12} md={6}>
              <Styled.LinkStyle
                href={
                  isExternalUrlHref(forBusinessContent?.path)
                    ? forBusinessContent?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + forBusinessContent?.path
                      )
                }
                target={
                  isExternalUrl(forBusinessContent?.path) ? '_blank' : '_self'
                }
              >
                <Styled.TopImage
                  src={forBusinessContent?.image?.file?.url}
                  alt={forBusinessContent?.image?.file?.title}
                />
                {documentToReactComponents(
                  JSON.parse(forBusinessContent?.richText?.raw),
                  optionsStyle
                )}
              </Styled.LinkStyle>
            </Styled.Column>
          </Styled.RowWrapper>
        </Styled.CustomerSelect>
      </Styled.MainModal>
    </>
  );
};
export default PropanePopUp;
