import * as React from 'react';
import { useState, useEffect } from 'react';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import TopBanner from '../../common/topBanner/topBanner';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import GlobalStyles from '../../styles/globalStyles';
import SubFooter from '../../components/subFooter/subFooter';
import LocationDetails from '../../pageSections/locations/locationDetails';
import { graphql } from 'gatsby';

const BreadcrumbsBlockData = {
  backgroundColor: '#FFC116',
  borderTopColor: '#E8A300',
  title: 'Locations',
};

const LocationPageTemplate = ({ data, location }) => {
  const path = location?.pathname?.split('/')[1];
  const [showMapLeftPanel, setShowMapLeftPanel] = useState(true);

  return (
    <div style={{ background: '#fff' }}>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar
        activePath={location.pathname}
        activePathImageTop="#037ec4"
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        {...BreadcrumbsBlockData}
        path={location.pathname}
        isLocation={true}
        location={location}
        onClick={() => {
          setShowMapLeftPanel(!showMapLeftPanel);
        }}
      />
      <LocationDetails DetailsData={data} />
      <Footer showProducts={true} />
      <SubFooter />
    </div>
  );
};

export default LocationPageTemplate;

export const pageQuery = graphql`
  query locationDetailsDataMyQuery1($pageSlug: String!) {
    allContentfulLocation(
      filter: {
        brand: { brand: { eq: "Columbia" } }
        urlSlug: { eq: $pageSlug }
      }
    ) {
      edges {
        node {
          address1
          address2
          node_locale
          branchHours
          branchManager
          cardlockHours
          email
          hasDef
          type {
            type
          }
          hasDiesel
          hasGasoline
          hasHeatingOil
          hasHeatingEquipment
          hasLubricants
          hasPropane
          latitude
          location
          longitude
          phone
          tollfree
          pipelineCardAccepted
          postalCode
          serviceArea {
            serviceArea
          }
          testimonial {
            raw
          }
          notes {
            raw
          }
          city
          province
          id
          urlSlug
          fax
        }
      }
    }
    allContentfulColumbiaFuel(
      filter: {
        node_locale: { eq: "en-US" }
        identifier: { type: { eq: "location" } }
      }
    ) {
      edges {
        node {
          title
          identifier {
            type
          }
          slug
          sections {
            ... on ContentfulContentList {
              id
              title
              type
              header
              list {
                type
                title
                header
                subText
                contentDetails {
                  raw
                }
              }
            }
            ... on ContentfulTextWithMultipleImagesReferences {
              id
              title
              type
              headerText
              imagereferences {
                ... on ContentfulMutipleImagesWithPath {
                  id
                  type
                  title
                  images {
                    title
                    file {
                      fileName
                      url
                    }
                  }
                  path
                }
              }
            }
            ... on ContentfulContent {
              id
              title
              type
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  }
`;
